import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import BlankLayout from '../layouts/BlankLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import DomainWrapper from '../layouts/DomainWrapper';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen loadable={true} />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: '/:domain',
      element: <DomainWrapper />,
      children: [
        {
          path: 'auth',
          children: [
            {
              path: 'login',
              element: (
                <GuestGuard>
                  <Navigate to={'../../'} replace state={{ openLogin: true }} />
                </GuestGuard>
              ),
            },
            // {
            //   path: 'register',
            //   element: (
            //     <GuestGuard>
            //       <Register />
            //     </GuestGuard>
            //   ),
            // },
            // { path: 'login-unprotected', element: <Login /> },
            // { path: 'register-unprotected', element: <Register /> },
            { path: 'reset-password', element: <ResetPassword /> },
            { path: 'new-password', element: <NewPassword /> },
            { path: 'verify', element: <VerifyCode /> },
          ],
        },

        // Home Routes
        {
          path: '',
          element: (
            // <AuthGuard>
            <DashboardLayout />
            // </AuthGuard>
          ),
          children: [
            // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: '', element: <Home />, index: true },
            { path: 'games/:categSlug', element: <GamesByCateg /> },
            { path: 'games/:categSlug/:companyId', element: <GamesByCateg /> },
            { path: 'tnc', element: <TermsAndConditions /> },
          ],
        },

        // History Routes
        {
          path: 'history',
          element: (
            // <AuthGuard>
            <DashboardLayout />
            // </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'bet', element: <GameHistory /> },
            { path: 'transaction', element: <TransactionHistory /> },
          ],
        },

        // User Routes
        {
          path: 'user',
          element: (
            // <AuthGuard>
            <DashboardLayout />
            // </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'account', element: <AccountProfile /> },
            { path: 'banks', element: <MemberBankList /> },
          ],
        },

        // Referral Route
        {
          path: 'referral',
          element: (
            // <AuthGuard>
            <DashboardLayout />
            // </AuthGuard>
          ),
          children: [
            // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { element: <Referral />, index: true },
          ],
        },

        // Bonus Route
        {
          path: 'vip',
          element: (
            // <AuthGuard>
            <DashboardLayout />
            // </AuthGuard>
          ),
          children: [{ element: <Vip />, index: true }],
        },
        {
          path: 'agent',
          element: (
            // <AuthGuard>
            <DashboardLayout />
            // </AuthGuard>
          ),
          children: [{ element: <Agent />, index: true }],
        },

        // Invite Routes
        {
          path: 'invite',
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [{ element: <Invite />, index: true }],
        },

        // Promotion Routes
        {
          path: 'promotion',
          element: (
            // <AuthGuard>
            <DashboardLayout />
            // </AuthGuard>
          ),
          children: [
            { element: <Promotion />, index: true },
            { path: 'view/:promotionId', element: <ViewPromotion /> },
          ],
        },

        {
          path: 'payStatus',
          element: <LogoOnlyLayout />,
          children: [{ path: ':paymentMethod', element: <PaymentStatus />, index: true }],
        },

        // AngPau
        {
          path: 'angpau',
          element: (
            // <AuthGuard>
            <AngPau />
            // </AuthGuard>
          ),
        },
      ],
    },

    {
      path: '',
      element: <BlankLayout />,
      children: [{ path: 'download', element: <DownloadPage /> }],
    },

    {
      path: '',
      element: <BlankLayout />,
      children: [{ path: 'site', element: <WrapDomain /> }],
    },

    {
      path: '',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: 'google9d00515fa23248b2.html', element: <></> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// HOME
const Home = Loadable(lazy(() => import('../views/game/Home')));

// TERMS AND CONSITIONS
const TermsAndConditions = Loadable(lazy(() => import('../views/terms/TermsAndConditions')));

// GAMES
const GamesByCateg = Loadable(lazy(() => import('../views/game/GamesByCateg')));

// GAME HISTORY
const GameHistory = Loadable(lazy(() => import('../views/history/GameHistory')));

// TRANSACTION HISTORY
const TransactionHistory = Loadable(lazy(() => import('../views/history/TransactionHistory')));

// USER
const AccountProfile = Loadable(lazy(() => import('../views/user/AccountProfile')));
const MemberBankList = Loadable(lazy(() => import('../views/user/MemberBankList')));

// INVITE
const Invite = Loadable(lazy(() => import('../views/share/Invite')));

// PROMOTION
const Promotion = Loadable(lazy(() => import('../views/promotion/Promotion')));
const ViewPromotion = Loadable(lazy(() => import('../views/promotion/ViewPromotion')));
const AngPau = Loadable(lazy(() => import('../views/promotion/AngPau')));

// PAYMENT STATS
const PaymentStatus = Loadable(lazy(() => import('../pages/PaymentStatus')));

// REFERRAL
const Referral = Loadable(lazy(() => import('../views/referral/Referral')));

// Bonus
const Vip = Loadable(lazy(() => import('../views/referral/Vip')));
const Agent = Loadable(lazy(() => import('../views/referral/Agent')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const DownloadPage = Loadable(lazy(() => import('../views/DownloadPage')));
const WrapDomain = Loadable(lazy(() => import('../views/WrapDomain')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
